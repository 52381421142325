import { FileTextOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Flex, Modal, notification } from "antd";
import _ from "lodash";
import React from "react";
import DemoFile from "@/components/SVGComponents/DemoFile";
import { FileDataOption } from "@/hooks/useGetFileList";
import { WarningItem } from "@/hooks/useUploadFile";
import payToLenvo from "@/utils/payToLenvo";
import { uuidV4 } from "@/utils/utils";
import AutoOverflowTip from "../AutoOverflowTip";
import Price from "../constants/Price";
import QuestionBulb from "../SVGComponents/QuestionBulb";

import styles from "./index.module.scss";

export const renderAQuestion = (text = "", onClick = _.noop) => {
  return (
    <li key={text} onClick={onClick} className={styles.aQuestion}>
      <QuestionBulb />
      <div>{text}</div>
    </li>
  );
};

export const renderErrorNotification = ({ title = "", desc = "" }) => {
  notification.error({
    className: styles.notification,
    message: <span style={{ fontSize: "16px", fontWeight: 600 }}>{title}</span>,
    description: <div style={{ fontSize: "14px" }}>{desc}</div>,
    placement: "bottomRight",
    duration: 0,
  });
};

export const renderWarningNotification = ({ title = "", subTitle = "", desc = "", uniqueKey = uuidV4(), open = false }) => {
  notification.error({
    key: uniqueKey,
    className: styles.warningNotification,
    message: <span style={{ fontSize: "16px", fontWeight: 600 }}>{title}</span>,
    description: (
      <div>
        <div className={styles.subTitle}>{subTitle}</div>
        {open ? (
          <div className={styles.desc}>{desc}</div>
        ) : (
          <Button type="link" onClick={() => renderWarningNotification({ title, subTitle, desc, uniqueKey, open: true })}>
            更多
          </Button>
        )}
      </div>
    ),
    placement: "bottomRight",
    duration: 0,
    icon: <WarningOutlined style={{ fontSize: "20px", color: "#FFBE27" }} />,
  });
};

export const renderWarningList = (warningList: WarningItem[]) => {
  return (
    warningList &&
    warningList?.length &&
    warningList.map((item: WarningItem) =>
      renderWarningNotification({ title: "文件处理警告", subTitle: item.title, desc: item.text, uniqueKey: undefined }),
    )
  );
};

export const renderToPayConfirm = () => {
  Modal.destroyAll();
  return Modal.confirm({
    className: styles.toPayConfirm,
    title: "已达到 20MB 限制。",
    icon: "",
    content: (
      <div>
        上传的文件超过了 20MB 的免费计划限制。
        <br />
        升级到我们的标准计划以获得更大的文件大小和额外的PPT配额。
        {renderPrice()}
      </div>
    ),
    footer: null,
    closable: true,
    centered: true,
  });
};

export const renderPrice = (style = {}) => (
  <Flex className={styles.subscribeBox} justify="space-between" align="center" style={style}>
    <div>
      <p style={{ color: "#fff", fontSize: "16px" }}>
        <span style={{ color: "rgba(255, 255, 255, 0.60)", textDecoration: "line-through" }}>￥15.99</span> ￥9.99/30天
      </p>
      <p style={{ color: "rgba(255, 255, 255, 0.60)", fontSize: "12px" }}>单次购买</p>
    </div>
    <Button className={styles.subscribeBtn} onClick={() => payToLenvo(Price.getPriceInfo(Price.MONTH_PRICE))}>
      立即购买
    </Button>
  </Flex>
);

// ppt quota limit
export const renderPPTQuotaNotification = ({ title = "", desc = "" }) =>
  notification.error({
    className: styles.pptNotification,
    message: <span style={{ fontSize: "16px", fontWeight: 600 }}>{title}</span>,
    description: <div style={{ fontSize: "14px" }}>{desc}</div>,
    placement: "bottomRight",
    duration: 0,
  });

export const renderFileSelectOption = (o: any, showFile = false) => {
  const isDemo = !!o?.demo_file;
  const Icon = isDemo ? DemoFile : showFile ? FileTextOutlined : null;
  return (
    <div style={{ display: "flex", width: "100%", overflow: "hidden", alignItems: "center" }}>
      {Icon && <Icon style={{ marginRight: "8px", fontSize: isDemo ? "18px" : "14px", display: "block" }} />}
      <div style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}>
        <AutoOverflowTip title={o.label}>{o.label}</AutoOverflowTip>
      </div>
    </div>
  );
};

export const getFormattedSelectDataSource = (list: FileDataOption[] = []) => {
  return list.map(o => ({
    ...o,
    label: renderFileSelectOption(o),
    valueLabel: renderFileSelectOption(o, true),
  }));
};
