import { Avatar, Button, ConfigProvider, theme } from "antd";
import zhCN from "antd/locale/zh_CN";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import AutoOverflowTip from "@/components/AutoOverflowTip";
import Price from "@/components/constants/Price";
import useLoginStatusStore from "@/store/loginStatus";
import payToLenvo from "@/utils/payToLenvo";

import styles from "./Footer.module.scss";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useLoginStatusStore(state => state);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
      locale={zhCN}
    >
      <div className={styles.menuFooter}>
        <Button className={styles.newChat} onClick={() => navigate("/")}>
          + 新对话
        </Button>
        {!user?.is_subscribe ? (
          <div className={styles.trialWrapper}>
            {
              <div className={styles.flexRow}>
                <span style={{ fontSize: "12px" }}>免费试用</span>
                <div className={styles.flexRow}>
                  <Button className={styles.upgradeBtn} onClick={() => payToLenvo(Price.getPriceInfo(Price.MONTH_PRICE))}>
                    升级
                  </Button>
                </div>
              </div>
            }
          </div>
        ) : (
          <div className={styles.trialWrapper}>
            {
              <div className={styles.flexRow}>
                <span style={{ fontSize: "12px" }}>VIP</span>
                <div style={{ fontSize: "12px" }}>到期时间 {dayjs(user.vip_due_at).format("YYYY-MM-DD")}</div>
              </div>
            }
          </div>
        )}
        <div className={styles.accountInfoWrapper}>
          <div className={styles.flexRow}>
            <Avatar style={{ width: "32px" }} src={user.avatar}></Avatar>
            <div style={{ marginLeft: "8px", flex: "1", overflow: "hidden" }}>
              <div>{user.nickname}</div>
              <div style={{ color: "rgba(255,255,255,0.45)", fontSize: "12px" }}>
                <AutoOverflowTip title={user.email}>{user.email}</AutoOverflowTip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Footer;
