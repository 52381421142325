import { BulbOutlined, DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Tooltip, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import axios from "axios";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useContext, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import AutoOverflowTip from "@/components/AutoOverflowTip";
import Loading from "@/components/Loading";
import ChatOutlined from "@/components/SVGComponents/ChatOutlined";
import Toast from "@/components/Toast";
import { getFilesUrl } from "@/components/url";
import UserInfoContext from "@/contexts/UserInfoContext";
import useCreateChat from "@/hooks/useCreateChat";
import useGetFileList from "@/hooks/useGetFileList";
import { ACCEPT } from "@/hooks/useUploadFile";
import { axiosError, axiosResponseHandler } from "@/utils/axiosError";
import FileStatus from "./FileStatus";

import styles from "./index.module.scss";

const Files: React.FC = () => {
  const navigate = useNavigate();

  const { dataSource, updateData, total, loading, loadMore } = useGetFileList({ limit: 30 });
  const { createChat, loadingFileUid } = useCreateChat();

  const [deleteLoadingUid, setDeleteLoadingUid] = useState("");
  const [fileList, setFileList] = useState<RcFile[]>([]);

  const { disabledUpload } = useContext(UserInfoContext);

  const text = (
    <p>
      重要提示：上传的数据文件将在<span style={{ fontWeight: "700" }}>24小时</span>后自动删除，请及时保存重要内容。
    </p>
  );

  const uploadSuccessCallback = (filename = "") => {
    Toast.success(`${filename} 上传成功！`);
    updateData();
  };

  const deleteFile = (label = "", value = "") => {
    setDeleteLoadingUid(value);
    axios
      .delete(getFilesUrl(value))
      .then(axiosResponseHandler)
      .then(() => {
        Toast.success(`成功删除 ${label}！`);
        updateData();
      })
      .catch(axiosError)
      .finally(() => setDeleteLoadingUid(""));
  };

  const onDelete = (file: any) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <h1 className={styles.title}>文件</h1>
      <Alert banner message={text} icon={<BulbOutlined />} style={{ padding: "8px 16px" }} />
      <Upload.Dragger
        accept={ACCEPT}
        multiple={true}
        className={styles.upload}
        disabled={disabledUpload}
        showUploadList={false}
        beforeUpload={file => {
          setFileList(f => [...f, file] as any);
          return false;
        }}
      >
        <div>
          <p>
            <InboxOutlined style={{ color: disabledUpload ? "" : "#6A3AC7", fontSize: "48px" }} />
          </p>
          <p>点击或拖动文件到此区域进行上传</p>
          <p style={{ color: "rgba(0,0,0,.45)" }}>支持单个或批量上传</p>
        </div>
      </Upload.Dragger>
      {!_.isEmpty(fileList) && (
        <div style={{ padding: "16px 0" }}>
          {fileList.map(f => (
            <FileStatus
              key={f.uid}
              file={f}
              onDelete={() => onDelete(f)}
              onSuccess={(filename: string) => {
                onDelete(f);
                uploadSuccessCallback(filename);
              }}
            />
          ))}
        </div>
      )}
      <ul className={styles.list} id="scrollableDiv">
        {loading ? (
          <Loading />
        ) : (
          <InfiniteScroll
            dataLength={dataSource.length} // 实际的数据长度，触发滚动加载
            next={loadMore}
            hasMore={dataSource.length < total}
            loader={<Loading style={{ margin: "16px" }} />}
            endMessage={dataSource.length > 50 && <Divider plain>这是全部内容，没有更多了</Divider>}
            scrollableTarget="scrollableDiv"
          >
            {dataSource.map((row, rowIndex) => (
              <li key={rowIndex}>
                <div style={{ padding: "16px 12px", overflow: "hidden" }}>
                  <AutoOverflowTip title={row.label} overlayInnerStyle={{ width: "400px" }} />
                </div>
                <div>
                  <span style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.45)", marginRight: "24px" }}>
                    {dayjs(row.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                  </span>
                  <Tooltip title="使用此文件创建新的对话">
                    <Button
                      size="small"
                      className={styles.btn}
                      shape="circle"
                      icon={<ChatOutlined />}
                      loading={loadingFileUid === row.value}
                      onClick={() =>
                        createChat({
                          chatTitle: row.label,
                          fileUid: row.value,
                          successCallback: chatUid => navigate(`/chats/${chatUid}`),
                        })
                      }
                    />
                  </Tooltip>
                  <Tooltip title="移除此文件">
                    <Button
                      loading={row.value === deleteLoadingUid}
                      className={styles.btn}
                      size="small"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() => deleteFile(row.label as string, row.value)}
                    />
                  </Tooltip>
                </div>
              </li>
            ))}
          </InfiniteScroll>
        )}
      </ul>
    </div>
  );
};

export default Files;
