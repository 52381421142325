import { EditOutlined } from "@ant-design/icons";
import { Flex, Input } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const PrivacyDocsRow: React.FC = () => {
  const navigate = useNavigate();

  const style = {
    cursor: "pointer",
    margin: "0 4px",
  };

  return (
    <Flex justify="center" align="center" style={{ padding: "3px 0 16px 0", fontSize: "12px", color: "rgba(0,0,0,.45)" }}>
      以上内容均由 AI 生成，仅供参考和借鉴{"     "}京ICP备18026350号{"     "}
      <span style={style} onClick={() => navigate("/docs/user-agreement")}>
        用户协议
      </span>
      |
      <span style={style} onClick={() => navigate("/docs/privacy-policy")}>
        隐私政策
      </span>
    </Flex>
  );
};

export default PrivacyDocsRow;
