import { DownOutlined } from "@ant-design/icons";
import { Avatar, Button } from "antd";
import dayjs from "dayjs";
import hash from "object-hash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import GlobalFeedbackButton from "@/components/GlobalFeedbackButton";
import Loading from "@/components/Loading";
import useLoginStatusStore from "@/store/loginStatus";
import { getCacheValue } from "@/utils/utils";
import AttachmentFile from "../AttachmentFile";
import FeedbackButtonAndModal from "./FeedbackButtonAndModal";
import MessageMarkdown from "./MessageMarkdown";
import useLoadMessageList from "./useLoadMessageList";

import styles from "./index.module.scss";

const BOTTOM_HEIGHT = 20;

const MessageList: React.FC = () => {
  const { cacheData, loadHistoryPre, cacheScroll, loadingMore, initialLoading, messages, loadAMessage } = useLoadMessageList();

  const { user } = useLoginStatusStore(state => state);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const [unRead, setUnRead] = useState(0);
  const [isBottom, setIsBottom] = useState(false); // 是否滚动了底部

  const cacheLastViewId = useCallback(getCacheValue(""), []);

  const renderResponse = (mId: any) => {
    const msg = cacheData.dataMap[mId];
    if (!msg) return;

    const { sender, text, created_at, attachments } = msg;
    const key = hash(msg);

    return (
      <div className={styles.response} key={key} data-uid={mId}>
        {sender ? <Avatar className={styles.you} src={sender.avatar}></Avatar> : <Avatar className={styles.system}>Doli</Avatar>}
        <div style={{ flex: "1" }}>
          <div>
            {sender ? "你" : "@Doli"}
            <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "12px", marginLeft: "6px" }}>
              {dayjs(created_at).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </div>
          <div style={{ marginTop: "6px" }}>
            <MessageMarkdown text={text} />
            {(attachments || []).map((id: string) => (
              <AttachmentFile key={id} id={id} />
            ))}
            <FeedbackButtonAndModal message={msg} updateMessage={loadAMessage} />
          </div>
        </div>
      </div>
    );
  };

  // 鼠标滚动
  const onWheel = (e: any) => {
    // 向上滚动了
    if (e.deltaY < 0 && !cacheScroll.scrollLoading && scrollRef.current && scrollRef.current.scrollTop < 40) {
      loadHistoryPre();
    }
  };

  const updateLastView = () => {
    if (scrollRef.current && messages.length) {
      const { scrollTop, clientHeight, childNodes, lastChild } = scrollRef.current;

      let currentLastViewId = "";
      childNodes.forEach((cNode: any) => {
        if (cNode) {
          const { offsetTop, clientHeight: cHeight } = cNode;

          const uid = cNode.getAttribute("data-uid");
          if (offsetTop + cHeight < scrollTop + clientHeight) {
            currentLastViewId = uid;
          }
        }
      });

      if (!currentLastViewId) currentLastViewId = (lastChild as any)?.getAttribute("data-uid");
      if (!cacheLastViewId()) {
        cacheLastViewId(currentLastViewId);
        return;
      }

      const lastId = cacheLastViewId();
      const currentLastViewIdIndex = messages.indexOf(currentLastViewId);
      const lastViewIdIndex = messages.indexOf(lastId);

      const newLastId = currentLastViewIdIndex >= lastViewIdIndex ? currentLastViewId : lastId;
      cacheLastViewId(newLastId);

      const len = messages.length - 1;
      const lastIndex = messages.indexOf(newLastId);
      setUnRead(Math.max(0, len - lastIndex));
    }
  };

  const onScroll = (e: any) => {
    updateLastView();
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    setIsBottom(scrollHeight - BOTTOM_HEIGHT <= scrollTop + clientHeight);

    const { scrollTop: cacheScrollTop, scrollLoading } = cacheScroll;
    if (scrollLoading) return;

    if (cacheScrollTop === undefined) {
      cacheScroll.scrollTop = scrollTop;
    } else if (scrollTop < 40 && scrollTop < cacheScrollTop) {
      loadHistoryPre();
    }
  };

  useEffect(updateLastView);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      const { scrollHeight, clientHeight } = scrollRef.current;
      scrollRef.current.scrollTop = scrollHeight - clientHeight;
      setIsBottom(true);
    }
  };

  useEffect(() => {
    if (!initialLoading) {
      scrollToBottom();
    }
  }, [initialLoading]);

  return (
    <div style={{ flex: 1, overflow: "hidden", position: "relative" }}>
      <div
        style={{ overflow: "auto", height: "100%", position: "relative", paddingBottom: `${BOTTOM_HEIGHT}px` }}
        onScroll={onScroll}
        onWheel={onWheel}
        ref={scrollRef}
      >
        {loadingMore && <Loading />}
        {initialLoading ? <Loading /> : messages.map(renderResponse)}
      </div>
      <div className={styles.fixedArea}>
        {(!isBottom || !!unRead) && (
          <Button className={styles.toBottom} icon={<DownOutlined style={{ paddingTop: "4px" }} />} onClick={scrollToBottom}>
            {unRead ? `${unRead} 新消息` : ""}
          </Button>
        )}
        {user?.can_generate && user?.can_upload && <GlobalFeedbackButton />}
      </div>
    </div>
  );
};

export default MessageList;
