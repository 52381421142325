import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Tooltip, Upload } from "antd";
import React, { useContext, useEffect } from "react";
import UserInfoContext from "@/contexts/UserInfoContext";
import useUploadFile, { ACCEPT } from "@/hooks/useUploadFile";
import ChatInfoContext from "@/pages/Chats/ChatInfoContext";
import { getFormatFileDataOption } from "@/utils/common";

import styles from "./index.module.scss";

const UploadArea: React.FC = () => {
  const { sending, selectedFile, setSelectedFile } = useContext(ChatInfoContext);

  const { disabledUpload } = useContext(UserInfoContext);

  const { status, fileData, setFile } = useUploadFile();

  const isUploading = status === "uploading" || status === "processing";

  useEffect(() => {
    if (status === "done" || status === "warning") {
      const newFile = fileData && getFormatFileDataOption(fileData);
      // 用户上传文件后，自动切换成用户传的文件
      if (newFile && newFile.uid !== selectedFile?.uid) {
        setSelectedFile(newFile);
      }
    }
  }, [status, fileData]);

  return (
    <>
      <Upload
        showUploadList={false}
        accept={ACCEPT}
        beforeUpload={file => {
          setFile(file);
          return false;
        }}
        maxCount={1}
      >
        <Tooltip
          title={
            <div>
              上传文件
              <p style={{ color: "rgba(255, 255, 255, 0.50)" }}>提示：请上传 .csv 或 .xlsx 文件</p>
            </div>
          }
        >
          <Button
            type="text"
            icon={isUploading ? <LoadingOutlined /> : <UploadOutlined />}
            className={styles.btn}
            disabled={sending || disabledUpload || isUploading}
          />
        </Tooltip>
      </Upload>
    </>
  );
};

export default UploadArea;
