import axios from "axios";
import _ from "lodash";
import React, { ReactNode, useEffect, useState } from "react";
import ErrorCode from "@/components/constants/ErrorCode";
import LocalStorageKeys from "@/components/constants/LocalStorageKeys";
import { getUserInfoUrl } from "@/components/url";
import useLoginStatusStore from "@/store/loginStatus";
import { axiosError, axiosResponseHandler, loginErrorHandler } from "@/utils/axiosError";

export interface User {
  avatar?: string;
  email?: string;
  email_verified?: boolean;
  nickname?: string;
  is_subscribe?: boolean; // 是否订阅
  can_generate?: boolean; // ppt quota
  can_upload?: boolean; // file quota
  mark?: number;
  uid?: number;
  vip_due_at?: string;
}

interface ContextValues {
  user?: User;
  updateUser: () => void;
  disabledChat: boolean;
  disabledUpload: boolean;
  isLogin: boolean;
}

const UserInfoContext = React.createContext<ContextValues>({
  user: {},
  updateUser: _.noop,
  disabledChat: false,
  disabledUpload: false,
  isLogin: false,
});

export default UserInfoContext;

interface IProps {
  children?: ReactNode;
}

export const UserInfoContextProvider: React.FC<IProps> = ({ children }) => {
  const { isLogin, user, setUser, refreshKey } = useLoginStatusStore(state => state);

  const disabledChat = !!user && (!user.can_generate || !user.can_upload);
  const disabledUpload = disabledChat;

  const loadUser = () => {
    axios
      .post(getUserInfoUrl())
      .then(axiosResponseHandler)
      .then(r => {
        const { data } = r.data || {};
        setUser(data);
      })
      .catch(r => {
        const { code } = r;
        if (ErrorCode.loginErrors.includes(code)) {
          return loginErrorHandler(code);
        }
        axiosError(r);
      });
  };

  useEffect(() => {
    isLogin && loadUser();
  }, [isLogin]);

  useEffect(() => {
    refreshKey && loadUser();
  }, [refreshKey]);

  const resetUrl = () => {
    const prevUrl = localStorage.getItem(LocalStorageKeys.FORMER_LOCATION_URL);
    if (prevUrl) {
      localStorage.removeItem(LocalStorageKeys.FORMER_LOCATION_URL);
      window.location.href = prevUrl;
    }
  };
  useEffect(resetUrl, []);

  return (
    <UserInfoContext.Provider value={{ user, updateUser: loadUser, disabledChat, disabledUpload, isLogin }}>
      {children}
    </UserInfoContext.Provider>
  );
};
