export default class Price {
  static MONTH_PRICE = "month";

  static YEAR_PRICE = "year";

  static TEST_PRICE = "test";

  static getPriceInfo(type = "") {
    if (type === this.MONTH_PRICE) {
      return {
        uid: "846b3bed40a644a7975b7bd2528fddd3",
        name: "一月会员",
        price: 999,
        action: {
          type: "vip_days",
          days: 30,
        },
      };
    }

    if (type === this.YEAR_PRICE) {
      return {
        uid: "403f02cd67a7412384b7a52db5fdb900",
        name: "一年会员",
        price: 9900,
        action: {
          type: "vip_days",
          days: 365,
        },
      };
    }

    if (type === this.TEST_PRICE) {
      return {
        uid: "5887cce85c704d4faf9f4bff0457f73f",
        name: "测试",
        price: 1,
        action: {
          type: "vip_days",
          days: 30,
        },
      };
    }

    return {};
  }
}
