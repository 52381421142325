import { ConfigProvider, Layout } from "antd";
import zhCN from "antd/locale/zh_CN";
import React from "react";
import { ChatsContextProvider } from "@/contexts/ChatsContext";
import Footer from "./Footer";
import SideMenu from "./SideMenu";
import SubscribeBanner from "./SubscribeBanner";

import styles from "./index.module.scss";

const { Content, Sider } = Layout;

interface LayoutComponentProps {
  children: React.ReactNode;
}

const LayoutComponent: React.FC<LayoutComponentProps> = ({ children = null }) => {
  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        token: {
          colorPrimary: "#6A3AC7",
          colorError: "#F52230",
          colorInfo: "#6A3AC7",
          colorSuccess: "#29CC97",
        },
        components: {
          Button: {
            borderRadius: 100,
            defaultBg: "rgba(0, 0, 0, 0.05)",
            defaultBorderColor: "transparent",
          },
          Input: {
            colorBgContainer: "transparent",
            colorBgContainerDisabled: "rgba(0, 0, 0, 0.05)",
          },
          Select: {
            colorBgContainer: "rgba(0, 0, 0, 0.05)",
            colorBorder: "transparent",
            colorBgContainerDisabled: "rgba(0, 0, 0, 0.05)",
          },
        },
      }}
    >
      <ChatsContextProvider>
        <Layout hasSider>
          <Sider className={styles.slider}>
            <SideMenu />
            <Footer />
          </Sider>
          <Layout style={{ background: "#F5F6F7" }}>
            <Content style={{ margin: "0", overflow: "hidden", height: "100vh" }}>{children}</Content>
            <SubscribeBanner />
          </Layout>
        </Layout>
      </ChatsContextProvider>
    </ConfigProvider>
  );
};

export default LayoutComponent;
