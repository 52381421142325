import React from "react";
import { apiOriginUrl } from "@/components/url";

export default function UserAgreement() {
  return (
    <iframe
      style={{ width: "100%", overflowX: "hidden", height: "100%", overflow: "auto", padding: "20px 24px" }}
      src={`${apiOriginUrl}user-agreement/`}
    />
  );
}
