import { UpOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { renderAQuestion } from "@/components/GlobalRenders";

interface Props {
  questions?: string[];
  onClickQuestion?: (v: string) => void;
}

const QuestionArea: React.FC<Props> = ({ questions = [], onClickQuestion = _.noop }) => {
  const [close, setClose] = useState(false);

  const closeStyle: React.CSSProperties = {
    width: "160px",
    position: "absolute",
    top: "-38px",
  };

  return (
    <div
      style={{
        background: "#6A3AC7",
        borderRadius: " 6px 6px 0px 0px",
        padding: "10px",
        boxShadow: "0 6px #6A3AC7",
        ...(close ? closeStyle : {}),
      }}
    >
      {!close ? (
        <>
          <Flex style={{ color: "#fff", marginBottom: "6px" }} justify="space-between">
            试试问我这些问题
            <div onClick={() => setClose(true)} style={{ cursor: "pointer", color: "rgba(255,255,255,0.65)" }}>
              关闭
            </div>
          </Flex>
          <Flex gap={8} style={{ flexWrap: "wrap" }}>
            {questions.map(t => renderAQuestion(t, () => onClickQuestion(t)))}
          </Flex>
        </>
      ) : (
        <div onClick={() => setClose(false)} style={{ cursor: "pointer", color: "#fff" }}>
          显示建议 <UpOutlined />
        </div>
      )}
    </div>
  );
};

export default QuestionArea;
