export default class LocalStorageKeys {
  static FORMER_LOCATION_URL = "formerLocationUrl"; // 记录用户跳出当页面时候的路由

  static AUTO_CREATE_A_DEMO_CHAT = "autoCreateADemoChat"; // 自动创建一个 demo chat

  static FILE_QUESTION_CLICKED_LIST = "fileQuestionClickedList"; // 文件对应的被点击过的问题

  static LENVO_SYSTEM_INFO = "lenvoSystemInfo"; // 联想 getSystemInfo 信息

  static LENVO_LANUCH_OPTIONS = "lenvoLanuchOptions"; // 联想 getLaunchOptionsSync 信息

  static LENVO_AUTHORIZATION = "lenvoAuthorization"; // 联想 token 信息
}
