import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { Badge, Button, Flex, Input, Select, Upload } from "antd";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LocalStorageKeys from "@/components/constants/LocalStorageKeys";
import FakeProcessModal from "@/components/FakeProcessModal";
import GlobalFeedbackButton from "@/components/GlobalFeedbackButton";
import { getFormattedSelectDataSource, renderAQuestion } from "@/components/GlobalRenders";
import Loading from "@/components/Loading";
import SenderArrow from "@/components/SVGComponents/SenderArrow";
import ChatsContext from "@/contexts/ChatsContext";
import UserInfoContext, { User } from "@/contexts/UserInfoContext";
import useCreateChat from "@/hooks/useCreateChat";
import useGetFileList, { FileDataOption } from "@/hooks/useGetFileList";
import useGetFileQuestions from "@/hooks/useGetFileQuestions";
import useUploadFile, { ACCEPT, UploadFileFlowStatus } from "@/hooks/useUploadFile";
import useLoginStatusStore from "@/store/loginStatus";

import Logo from "@/assets/svg/doli.svg";

import styles from "./index.module.scss";

const FreshmanView: React.FC = () => {
  const navigate = useNavigate();

  const { dataSource, setQ, loadMore, loading: filesLoading, updateData } = useGetFileList({});
  const { createChat, loadingFileUid } = useCreateChat();
  const { file, fileData, status, setFile } = useUploadFile();
  const { questions, loading: loadingQuestion, updateFileUid, markedQuestion } = useGetFileQuestions();

  const { user } = useLoginStatusStore(state => state);

  const { disabledUpload, disabledChat } = useContext(UserInfoContext);
  const { chatList, loading: loadingChatList } = useContext(ChatsContext);

  const [text, setText] = useState("");
  const [selectedFile, setSelectedFile] = useState<FileDataOption>();

  useEffect(() => {
    if (selectedFile?.uid) {
      updateFileUid(selectedFile.uid);
    }
  }, [selectedFile]);

  // 初始化加载数据，选中第一条
  useEffect(() => {
    if (!filesLoading && dataSource[0]) {
      setSelectedFile(dataSource[0]);
    }
  }, [filesLoading, dataSource]);

  const renderListLabel = (num = 1, text = "") => {
    return (
      <div className={styles.numLabel}>
        <Badge count={num} color="#E2D1F4" style={{ marginRight: "6px", color: "#6A3AC7" }} />
        {text}
      </div>
    );
  };

  const onPopupScroll = (e: any) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    if (scrollTop + clientHeight * 2 > scrollHeight) {
      loadMore();
    }
  };

  const getStatusText = (status: UploadFileFlowStatus) => {
    if (status === "uploading") return "上传中";
    if (status === "processing") return "处理中";
    return "上传文件";
  };

  useEffect(() => {
    if (status === "done" && fileData) {
      updateData();
      setSelectedFile({ label: fileData.file_name, value: fileData.uid, key: fileData.uid });
    }
  }, [fileData, status]);

  const isUploading = status === "uploading" || status === "processing";

  const getDataSource = () => {
    if (!selectedFile) return dataSource;
    if (dataSource.find(d => d.uid === selectedFile.uid)) return dataSource;
    return [selectedFile, ...dataSource];
  };

  const getAutoCreateAChatKey = () => `${LocalStorageKeys.AUTO_CREATE_A_DEMO_CHAT}${user?.email}${user?.nickname}`;

  // 是否是需要自动创建 demo chat 的用户，并且还没有创建过 demo chat
  const isAutoDemoUser = (user: User) => {
    if (!user) return;
    const isMarked = _.isNumber(user.mark) && user.mark % 2 === 0;
    const hasMarkedInLocal = !!localStorage.getItem(getAutoCreateAChatKey());
    const noChatHistory = chatList && chatList.length === 0;
    if (isMarked && !hasMarkedInLocal && noChatHistory) return true;
    return false;
  };

  useEffect(() => {
    const demoFile = dataSource.find(d => d.demo_file);
    if (!loadingChatList && user && isAutoDemoUser(user) && demoFile) {
      localStorage.setItem(getAutoCreateAChatKey(), "1");
      createChat({
        chatTitle: demoFile.label,
        fileUid: demoFile.value,
        successCallback: chatUid => navigate(`/chats/${chatUid}`),
        text,
        isAutoCreate: true, // 自动创建增加 thread 标记字段
      });
    }
  }, [user, dataSource, loadingChatList]);

  if (!user) return <Loading />; // TODO 去掉 demo 文件逻辑

  const onCreate = (t: string = text) => {
    if (!selectedFile) return;
    createChat({
      chatTitle: selectedFile.label,
      fileUid: selectedFile.value,
      successCallback: chatUid => navigate(`/chats/${chatUid}`),
      text: t,
    });
  };

  const onClickQuestion = (text: string) => {
    if (!text) return;
    markedQuestion(text);
    onCreate(text);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          将您的数据转化为可操作的演示文稿
          <img src={Logo} alt="" style={{ marginLeft: "9px" }} />
        </h1>

        {/* stepsContainer */}
        <div className={styles.stepsContainer}>
          <div>
            {renderListLabel(1, "选择您的数据")}
            <div className={styles.numValue}>
              <Select
                showSearch
                loading={filesLoading}
                filterOption={false}
                className={`${styles.selectFile} ${status === "error" ? styles.error : ""}`}
                options={getFormattedSelectDataSource(getDataSource())}
                value={status === "error" ? file?.name : selectedFile?.value}
                placeholder={file && status !== "error" ? file.name : "选择一个文件"}
                onChange={(v, o) => setSelectedFile(o as FileDataOption)}
                onSearch={setQ}
                onPopupScroll={onPopupScroll}
                status={status === "error" ? "error" : ""}
                onClick={() => (status === "error" ? setFile(undefined) : _.noop)}
                optionLabelProp="valueLabel"
              />
              <Upload
                accept={ACCEPT}
                maxCount={1}
                showUploadList={false}
                beforeUpload={file => {
                  setFile(file);
                  setSelectedFile(undefined);
                  return false;
                }}
              >
                <Button icon={isUploading ? <LoadingOutlined /> : <UploadOutlined />} disabled={disabledUpload || isUploading}>
                  {getStatusText(status as UploadFileFlowStatus)}
                </Button>
              </Upload>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            {renderListLabel(2, "描述你的问题（或暂不输入，看看 AI 的自动分析）")}
            <Input placeholder="在此处输入您的问题" value={text} onChange={e => setText(e.target.value)} />
          </div>
        </div>
        <Button
          type="primary"
          className={styles.createBtn}
          loading={!!loadingFileUid}
          onClick={() => onCreate()}
          disabled={!selectedFile?.value || isUploading || disabledChat || loadingQuestion}
        >
          创建新的对话 <SenderArrow style={{ marginLeft: "8px" }} />
        </Button>

        {!!questions?.length && selectedFile?.value && !disabledChat && (
          <Flex vertical gap={4} align="flex-start" style={{ marginTop: "32px" }}>
            <div style={{ color: "rgba(0, 0, 0, 0.45)" }}>从这些问题开始分析</div>
            {questions.map(t => renderAQuestion(t, () => onClickQuestion(t)))}
          </Flex>
        )}

        <FakeProcessModal
          key={file?.uid}
          title={file?.name}
          start={status === "uploading"}
          end={status && status !== "uploading"}
          error={status === "error"}
        />
      </div>
      {user?.can_generate && user?.can_upload && (
        <div className={styles.feedback}>
          <GlobalFeedbackButton />
        </div>
      )}
    </div>
  );
};

export default FreshmanView;
