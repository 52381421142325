import { create } from "zustand";
import { User } from "@/contexts/UserInfoContext";

interface State {
  isLogin: boolean;
  user: User;
  refreshKey: number;
  setIsLogin: (v: boolean) => void;
  setUser: (v: User) => void;
  updateRefreshKey: () => void;
}

const useLoginStatusStore = create<State>((set: any, get: any) => ({
  isLogin: false,
  user: {},
  refreshKey: 0,
  setIsLogin: (v: boolean) => set({ isLogin: v }),
  setUser: (v: User) => set({ user: v }),
  updateRefreshKey: () => set({ refreshKey: get().refreshKey + 1 }),
}));

export default useLoginStatusStore;
