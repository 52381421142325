import Chats from "./pages/Chats";
import PrivacyPolicy from "./pages/Docs/PrivacyPolicy";
import UserAgreement from "./pages/Docs/UserAgreement";
import Files from "./pages/Files";
import FreshmanView from "./pages/FreshmanView";

export default [
  {
    path: "*",
    page: FreshmanView,
  },
  {
    path: "/files/",
    page: Files,
  },
  {
    path: "/chats/:id",
    page: Chats,
  },
  {
    path: "/docs/privacy-policy",
    page: PrivacyPolicy,
  },
  {
    path: "/docs/user-agreement",
    page: UserAgreement,
  },
];
