import { Button, Form, Input, message as Toast, Modal, Tooltip } from "antd";
import axios from "axios";
import _ from "lodash";
import React, { useContext, useState } from "react";
import Approve from "@/components/SVGComponents/Approve";
import UnApproveFilled from "@/components/SVGComponents/UnApproveFilled";
import { getChatMessageFeedbackUrl } from "@/components/url";
import ChatInfoContext from "@/pages/Chats/ChatInfoContext";
import { axiosError, axiosResponseHandler } from "@/utils/axiosError";
import { Message } from "../useLoadMessageList";

import styles from "./index.module.scss";

interface Props {
  message: Message;
  updateMessage: any;
}

const FeedbackButtonAndModal: React.FC<Props> = ({ message, updateMessage }) => {
  const initialSelected = [1];

  const { feedback, id } = message;
  const [form] = Form.useForm();
  const [selected, setSelected] = useState<number[]>(initialSelected);
  const [text, setText] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const { chatInfo } = useContext(ChatInfoContext);

  const initial = () => {
    setOpen(false);
    setText("");
    setSelected(initialSelected);
  };

  const hasApprove = feedback && !_.isNil(feedback);
  const disabledApprove = _.isBoolean(feedback?.is_helpful);

  const OTHER_VALUE = 5;

  const onFeedback = (isApproved = false) => {
    if (!chatInfo) return;
    setDisabled(true);
    const payload: any = { is_helpful: isApproved };
    if (!isApproved) {
      payload.choices = selected;
      text && (payload.feedback = text);
    }
    axios
      .post(getChatMessageFeedbackUrl(chatInfo.uid, id), payload)
      .then(axiosResponseHandler)
      .then(() => {
        updateMessage?.(id);
        Toast.success("感谢您的反馈！");
        initial();
      })
      .catch(r => {
        axiosError(r);
        setDisabled(false);
      });
  };

  const choices = [
    {
      label: "无法理解",
      value: 1,
    },
    {
      label: "错误的结果",
      value: 2,
    },
    {
      label: "速度慢",
      value: 3,
    },
    {
      label: "模板不满意",
      value: 4,
    },
    {
      label: "其他",
      value: OTHER_VALUE,
    },
  ];

  if (!hasApprove) return null;

  return (
    <>
      <div style={{ marginTop: "6px", display: "flex", alignItems: "center" }}>
        <Tooltip title="有帮助">
          <Button
            disabled={disabledApprove || disabled}
            type="text"
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            icon={feedback?.is_helpful === true ? <UnApproveFilled style={{ transform: "rotate(180deg)", color: "black" }} /> : <Approve />}
            onClick={() => onFeedback(true)}
          />
        </Tooltip>
        <Tooltip title="没什么帮助">
          <Button
            type="text"
            disabled={disabledApprove || disabled}
            icon={
              feedback?.is_helpful === false ? (
                <UnApproveFilled style={{ color: "black" }} />
              ) : (
                <Approve style={{ transform: "rotate(180deg)" }} />
              )
            }
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            onClick={() => setOpen(true)}
          />
        </Tooltip>
      </div>
      {open && (
        <Modal
          centered
          open
          closable
          title="告诉我们更多"
          width={680}
          onOk={() => onFeedback()}
          onCancel={() => initial()}
          okButtonProps={{ onClick: () => onFeedback(), disabled: !selected.length }}
          footer={(_, { OkBtn }) => <OkBtn />}
        >
          <Form form={form} layout="vertical" className={styles.form}>
            <Form.Item label="请选择原因（多选）">
              {choices.map(c => (
                <Button
                  key={c.value}
                  className={selected.includes(c.value) ? styles.selected : ""}
                  style={{ marginLeft: "10px" }}
                  onClick={() => setSelected(pre => (pre.includes(c.value) ? _.without(pre, c.value) : [...pre, c.value]))}
                >
                  {c.label}
                </Button>
              ))}
            </Form.Item>
            {selected.includes(OTHER_VALUE) && (
              <Form.Item label="其他（可选）">
                <Input.TextArea placeholder="欢迎提供其他建议和反馈" onChange={e => setText(e.target.value)} rows={2} />
              </Form.Item>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};

export default FeedbackButtonAndModal;
