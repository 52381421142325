import { CloseOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import GlobalFeedbackButton from "@/components/GlobalFeedbackButton";
import { renderPrice } from "@/components/GlobalRenders";
import useLoginStatusStore from "@/store/loginStatus";

import people from "@/assets/png/limitPeople.png";

import styles from "./SubscribeBanner.module.scss";

const SubscribeBanner: React.FC = () => {
  const { user } = useLoginStatusStore(state => state);

  const [closePPT, setClosePPT] = useState(false);
  const [closeUpload, setCloseUpload] = useState(false);

  const msg = {
    freePPTQuotaLimit: {
      title: "您已经达到了 Doli 免费PPT配额。",
      desc: "升级到标准计划，享受更大的文件大小和增加的PPT配额！",
    },
    freeUploadQuotaLimit: {
      title: "您的免费上传次数已用完。",
      desc: "为了保持这种神奇的体验，让我们升级吧！",
    },
    pptQuotaLimit: {
      title: "您本月的 50 个PPT限额已达到。下个月再来吧！",
      desc: "感谢您选择 Doli ！",
    },
    uploadQuotaLimit: {
      title: "您本月的 50 个Excel上传次数已用完。",
      desc: "为了继续前进，请下个月再来！",
    },
  };

  const renderFeedbackBtn = () => <GlobalFeedbackButton style={{ position: "absolute", top: "-50px", right: "30px" }} />;

  const renderFreeLimitBanner = ({ title = "", desc = "" }) => (
    <Flex className={styles.freeBanner} justify="space-between" align="center">
      {renderFeedbackBtn()}
      <div>
        <p className={styles.title}>{title}</p>
        <p className={styles.desc}>{desc}</p>
      </div>
      {renderPrice({ width: "382px", marginTop: "0" })}
    </Flex>
  );

  const renderLimitBanner = ({ title = "", desc = "", isPPT = false }) => (
    <Flex className={styles.banner} justify="space-between" align="center">
      {renderFeedbackBtn()}
      <div>
        <p className={styles.title}>{title}</p>
        <p className={styles.desc}>{desc}</p>
      </div>
      <img src={people} alt="" style={{ marginRight: "88px" }} />
      <CloseOutlined className={styles.close} onClick={() => (isPPT ? setClosePPT(true) : setCloseUpload(true))} />
    </Flex>
  );

  if (_.isEmpty(user)) return null;

  if (!user.can_generate) {
    if (user.is_subscribe) return !closePPT && renderLimitBanner({ ...msg.pptQuotaLimit, isPPT: true });
    else return renderFreeLimitBanner(msg.freePPTQuotaLimit);
  }

  if (!user.can_upload) {
    if (user.is_subscribe) return !closeUpload && renderLimitBanner(msg.uploadQuotaLimit);
    else return renderFreeLimitBanner(msg.freeUploadQuotaLimit);
  }

  return null;
};

export default SubscribeBanner;
