import { Modal } from "antd";
import to from "await-to-js";
import axios from "axios";
import _ from "lodash";
import React from "react";
import Toast from "@/components/Toast";
import { apiOriginUrl, getV1LenvoPaymentUrl, getV1LenvoPayPriceTableUrl } from "@/components/url";
import useLoginStatusStore from "@/store/loginStatus";
import { axiosError } from "./axiosError";

export default async function payToLenvo(priceInfo) {
  // 1.
  const jsbridge = window.jsbridge || {};
  const user = useLoginStatusStore.getState().user;

  // 0. 获取价格信息
  const [errorT, resultT] = await to(axios.get(getV1LenvoPayPriceTableUrl()));
  if (errorT) return axiosError(errorT);
  // 1. 创建订单，获取订单编号等
  const params = {
    payFee: priceInfo.price,
    attach: {
      product_uid: priceInfo.uid,
      user_uid: user.uid,
    },
  };
  const [error, r] = await to(axios.post(getV1LenvoPaymentUrl(), params));
  if (error) return axiosError(error);

  const object = r.data.data || {};

  const payObject = {
    payNotify: `${apiOriginUrl}callback/payments/lenovo/`, //支付通知地址：支付结果通知URL
    mchNo: object.mchNo, //商户订单号：开发者小程序H5页面内部订单号
    goodsCode: priceInfo.uid, //商品编码
    goodsName: priceInfo.name, //商品名称
    goodsDesc: "", //可选，商品描述
    payAmount: priceInfo.price, //支付金额（单位：分）
    attach: object.attach, //可选，开发者想要透传给payNotify的附加数据
  };
  // 2. 调起联想支付
  jsbridge
    .requestPayment(payObject)
    .then(r => {
      const { code, message, data, tradeNo } = r;
      if (code === 10000) {
        Modal.confirm({
          title: `是否支付成功？`,
          centered: true,
          icon: null,
          content: "",
          onOk: () => {
            // 更新用户信息
            useLoginStatusStore.getState().updateRefreshKey();
          },
          okText: "已支付成功",
          cancelText: "支付失败或取消支付",
        });
      } else {
        Toast.error(getCodeMessage(code, message));
      }
    })
    .catch(e => axiosError(e));
}

function getCodeMessage(code, message) {
  if (code === 10001) return "没有实名认证";
  if (code >= 10003 && code <= 10005) return "不符合防沉迷规定";
  return message || "支付失败";
}
