import { Button, Input } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ErrorCode from "@/components/constants/ErrorCode";
import SenderArrow from "@/components/SVGComponents/SenderArrow";
import { getChatThreadMessageUrl, getChatThreadsUrl } from "@/components/url";
import UserInfoContext from "@/contexts/UserInfoContext";
import useGetFileQuestions from "@/hooks/useGetFileQuestions";
import useLoginStatusStore from "@/store/loginStatus";
import { axiosError, axiosResponseHandler } from "@/utils/axiosError";
import ChatInfoContext from "../../ChatInfoContext";
import FileSelect from "../FileSelect";
import UploadArea from "../UploadArea";
import QuestionArea from "./QuestionArea";

import styles from "./index.module.scss";

const { TextArea } = Input;

const InputRow: React.FC = () => {
  const [v, setV] = useState("");

  const { chatInfo, sending, setSending, selectedFile, setSelectedFile } = useContext(ChatInfoContext);
  const { updateUser, disabledChat } = useContext(UserInfoContext);

  const { questions, updateFileUid, markedQuestion } = useGetFileQuestions();

  const { user } = useLoginStatusStore(state => state);

  const chatFileUid = chatInfo?.last_thread?.files?.[0];
  const fileUid = selectedFile && selectedFile?.uid !== chatFileUid ? selectedFile?.uid : "";

  useEffect(() => {
    fileUid ? updateFileUid(fileUid) : chatFileUid ? updateFileUid(chatFileUid) : {};
  }, [fileUid, chatFileUid]);

  const onError = (r: any) => {
    const { code } = r;
    if (ErrorCode.quotaExceededErrors.includes(code)) {
      updateUser();
    } else if (code === ErrorCode.FILE_DELETED) {
      setSelectedFile({ deleted: true });
    } else {
      axiosError(r);
    }
  };

  const onSendNewFileAndText = () => {
    setSending(true);
    const params: any = { files: [fileUid] };
    if (v) params.text = v;
    axios
      .post(getChatThreadsUrl(chatInfo?.uid), params)
      .then(axiosResponseHandler)
      .catch(onError);
  };

  const onSendText = (text = v) => {
    setSending(true);
    if (!text) return;
    axios
      .post(getChatThreadMessageUrl(chatInfo?.uid, chatInfo?.last_thread?.uid), { text })
      .then(axiosResponseHandler)
      .then(() => {
        setV("");
      })
      .catch(onError);
  };

  const onSend = () => {
    if (!chatInfo) return;
    if (fileUid) onSendNewFileAndText();
    else onSendText();
  };

  const isDisabled = disabledChat || sending;

  const onKeyDown = (e: any) => {
    const { keyCode, ctrlKey, shiftKey } = e;
    const EnterCode = 13;
    if (keyCode === EnterCode && !ctrlKey && !shiftKey) {
      onSend();
    }
  };

  const renderUnActiveAlert = () => <div className={styles.unActiveBar}>当前对话已过期。请上传新文件并创建新的聊天。</div>;

  if (!selectedFile) return null;
  // 如果文件已经被删除，表示当前 chat 已经失效了
  if (!chatInfo?.is_active) return renderUnActiveAlert();

  const showQuestions = !!questions.length && !sending && user?.can_generate && user?.can_upload;

  const onClickQuestion = (text: string) => {
    markedQuestion(text);
    onSendText(text);
  };

  return (
    <div style={{ margin: "10px 30px 4px", position: "relative" }}>
      {showQuestions && <QuestionArea questions={questions} onClickQuestion={onClickQuestion} />}
      <div style={{ position: "relative" }}>
        <TextArea
          placeholder="在这里输入您的问题"
          className={styles.input}
          autoSize={{ minRows: 1, maxRows: 100 }}
          value={v}
          onChange={e => setV(e.target.value)}
          disabled={isDisabled}
          onKeyDown={onKeyDown}
        />
        <div style={{ position: "absolute", left: "7px", top: "7px", display: "flex" }}>
          <UploadArea />
          <FileSelect />
        </div>
        <Button
          type="primary"
          className={styles.sendBtn}
          disabled={isDisabled || (!fileUid && !v)}
          loading={sending}
          onClick={onSend}
          icon={<SenderArrow style={{ color: "#fff" }} />}
        />
      </div>
    </div>
  );
};

export default InputRow;
