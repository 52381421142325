import { Select } from "antd";
import React, { useContext } from "react";
import { getFormattedSelectDataSource } from "@/components/GlobalRenders";
import UserInfoContext from "@/contexts/UserInfoContext";
import useGetFileList, { FileDataOption } from "@/hooks/useGetFileList";
import ChatInfoContext from "../../ChatInfoContext";

import styles from "./index.module.scss";

const FileSelect: React.FC = () => {
  const { sending, selectedFile, setSelectedFile } = useContext(ChatInfoContext);
  const { disabledChat } = useContext(UserInfoContext);

  const { dataSource, setQ, loading: filesLoading, loadMore } = useGetFileList({});

  const onPopupScroll = (e: any) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    if (scrollTop + clientHeight * 2 > scrollHeight) {
      loadMore();
    }
  };

  const getDataSource = () => {
    if (!selectedFile) return dataSource;
    if (dataSource.find(d => d.uid === selectedFile.uid)) return dataSource;
    return [selectedFile, ...dataSource];
  };

  const disabled = sending || disabledChat;

  return (
    <Select
      showSearch
      loading={filesLoading}
      disabled={disabled}
      value={selectedFile?.uid}
      className={`${styles.selectFile} ${disabled ? styles.disabled : ""}`}
      onSearch={setQ}
      filterOption={false}
      onPopupScroll={onPopupScroll}
      options={getFormattedSelectDataSource(getDataSource())}
      onChange={(v, option) => setSelectedFile(option as FileDataOption)}
      placeholder="请选择或上传"
      optionLabelProp="valueLabel"
    />
  );
};

export default FileSelect;
