import { CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Flex, Input, Popover } from "antd";
import zhCN from "antd/locale/zh_CN";
import axios from "axios";
import React, { useState } from "react";
import Toast from "@/components/Toast";
import { getFeedbackUrl } from "@/components/url";
import { axiosError, axiosResponseHandler } from "@/utils/axiosError";

interface Props {
  style?: React.CSSProperties;
}

import styles from "./index.module.scss";

const GlobalFeedbackButton: React.FC<Props> = ({ style = {} }) => {
  const [open, setOpen] = useState(false);
  const [v, setV] = useState("");

  const onJoin = () => {
    window.open("https://discord.gg/XyvvPwaT", "_blank");
  };

  const onSubmit = () => {
    axios
      .post(getFeedbackUrl(), { feedback: v })
      .then(axiosResponseHandler)
      .then(() => {
        Toast.success("反馈提交成功！");
        setOpen(false);
        setV("");
      })
      .catch(axiosError);
  };

  const content = (
    <div>
      <Flex vertical gap="8px" align="flex-start">
        <div className={styles.label}>有什么可以改进的地方吗？</div>
        <ConfigProvider
          locale={zhCN}
          theme={{
            components: {
              Input: {
                colorBorder: "transparent",
                colorBgContainer: "rgba(0, 0, 0, 0.05)",
              },
            },
          }}
        >
          <Input.TextArea rows={4} style={{ marginTop: "8px" }} onChange={e => setV(e.target.value)} />
        </ConfigProvider>

        <Button type="primary" disabled={!v} onClick={onSubmit}>
          提交
        </Button>
      </Flex>
    </div>
  );

  return (
    <Popover
      trigger="click"
      arrow={false}
      placement="topRight"
      title="反馈"
      content={content}
      open={open}
      onOpenChange={setOpen}
      rootClassName={styles.pop}
    >
      <Button
        className={styles.triggerBtn}
        style={style}
        icon={open ? <CloseOutlined style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.45)" }} /> : <QuestionCircleOutlined />}
      />
    </Popover>
  );
};
export default GlobalFeedbackButton;
