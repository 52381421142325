import to from "await-to-js";
import axios from "axios";
import LocalStorageKeys from "@/components/constants/LocalStorageKeys";
import { getV1LenvoKey } from "@/components/url";
import { axiosError } from "@/utils/axiosError";

// 初始化
// 1.登录，2.获取私钥，3.授权，4.setLoginStatus
export default function loginToLenvo({ callback }) {
  const jsbridge = window.jsbridge || {};

  function getLaunchOptionsSync() {
    jsbridge.getLaunchOptions().then(res => {
      localStorage.setItem(LocalStorageKeys.LENVO_LANUCH_OPTIONS, res.query);
    });
  }

  function getSystemInfo() {
    jsbridge.getSystemInfo().then(res => {
      localStorage.setItem(LocalStorageKeys.LENVO_SYSTEM_INFO, res);
    });
  }

  async function requestGrant() {
    const [error, r] = await to(axios.get(getV1LenvoKey()));
    if (error) return axiosError(error);

    const { appid, privateKey } = r.data.data || {};

    jsbridge
      .requestGrant(appid, privateKey)
      .then(() => {
        // 授权成功
        console.log("授权成功 ");
        callback(); // 重置下 isLogin 状态，然后显示页面
      })
      .catch(err => {
        // 授权失败
        console.log("授权失败 ", err); // todo
      });
  }

  function loginSuccessCallback(res) {
    const { token } = res;
    const auth = `lenovo_${token}`;
    axios.defaults.headers.common["Authorization"] = `Bearer ${auth}`; // 设置全局默认 header Authorization
    localStorage.setItem(LocalStorageKeys.LENVO_AUTHORIZATION, auth);
    jsbridge?.showAccountCenter?.(1); // 1.1.0 版本展示用户登出模块
    requestGrant();
  }

  // 初始化添加各种监听事件
  function addListeners() {
    // 监听登录状态变化
    jsbridge.onLoginStatusChange(res => {
      if (res.statusCode === 1) {
        console.log("登录成功", res);
        loginSuccessCallback(res);
      } else if (res.statusCode === 0) {
        console.log("退出登录", res); // todo ?
      }
    });
  }

  async function login() {
    // 获取用户信息
    jsbridge.getUserInfo().then(res => {
      if (res.statusCode === 1) {
        console.log("已经登录");
        loginSuccessCallback(res);
        return;
      } else {
        // 未登录，打开登录窗口
        jsbridge
          .showLogin()
          .then(() => {
            console.log("打开登录弹出框成功");
          })
          .catch(() => {
            console.log("打开登录弹出框失败"); // todo
          });
      }
    });
  }

  // 加各种监听事件
  addListeners();

  // 获取小程序信息
  getLaunchOptionsSync();
  getSystemInfo();

  // 登录状态检测 + 登录
  login();
}
