import _ from "lodash";
import React, { useEffect } from "react";
import Loading from "@/components/Loading";
import useLoginStatusStore from "@/store/loginStatus";
import loginToLenvo from "./loginToLenvo";

const Login: React.FC = () => {
  const setIsLogin = useLoginStatusStore(state => state.setIsLogin);

  useEffect(() => {
    // 联想登录
    loginToLenvo({ callback: () => setIsLogin(true) });
  }, []);

  return <Loading style={{ fontSize: "50px", marginTop: "200px" }} />;
};

export default Login;
