import type { AxiosResponse } from "axios";
import axios from "axios";
import _ from "lodash";
import ErrorCode from "@/components/constants/ErrorCode";
import Toast from "@/components/Toast";
import useLoginStatusStore from "@/store/loginStatus";

// 预处理结果,分发接口 200 的报错处理
export const axiosResponseHandler = (r: AxiosResponse) => {
  const { code } = r.data || {};
  if (code) return Promise.reject(r.data);
  else return Promise.resolve(r);
};

export const loginErrorHandler = (code: number) => {
  // 其他设备登录，登出
  if (ErrorCode.LOGIN_FROM_OTHER_DEVICE === code) {
    Toast.info("登录信息失效");
  } else if (ErrorCode.needLoginCodes.includes(code)) {
    useLoginStatusStore.getState().setIsLogin(false);
  }
};

export const axiosError: any = (error: any) => {
  if (_.isEmpty(error)) return;

  const { code } = error;
  console.log(`${code}:${ErrorCode.getText(code)}`); // 控制台

  // 全局的接口报错处理，根据错误码处理
  if (code) {
    // 登录状态跳转
    if (ErrorCode.loginErrors.includes(code)) return loginErrorHandler(code);
    if (ErrorCode.getText(code)) return Toast.error(ErrorCode.getText(code));
  }

  // axios 主动 cancel 请求，直接返回
  if (axios.isCancel(error)) return;

  // 网络异常，请求没有发起
  if (error.code === "ERR_NETWORK") return Toast.warning("网络连接错误，无法连接到服务器。请检查您的网络状态。");

  // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
  if (error.response) {
    const { status } = error.response;
    // 服务器发生错误
    if (Number.isInteger(status) && status >= 500 && status < 600) return Toast.error("服务器发生错误，请稍后重试。");
  }

  // 请求已经成功发起，但没有收到响应。其中 `e.request`:
  // - 在浏览器中是 XMLHttpRequest 的实例，
  // - 在 node.js 中是 http.ClientRequest 的实例
  if (error.request) {
    Toast.warning("服务器繁忙，请稍后重试。");
    console.error(error.request);
  }
};
